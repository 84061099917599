import axios from "axios";
import { BASE_URL } from "../../config";
import {
  FETCH_ALL_COOKS_REQUEST,
  FETCH_ALL_COOKS_SUCCESS,
  FETCH_ALL_COOKS_FAILURE,
  SET_ALL_COOKS,
  FLAG_COOK_SUCCESS,
  FLAG_COOK_REQUEST,
  FLAG_COOK_FAILURE,
  SAVE_HOME_PAGE_CURRENT_FILTERS,
  SAVE_ASSIGNED_COOKS_CURRENT_FILTERS,
} from "./fetchAllCooksTypes";

const fetchAllCooksRequest = () => {
  return {
    type: FETCH_ALL_COOKS_REQUEST,
  };
};
const fetchAllCooksSuccess = (data) => {
  return {
    type: FETCH_ALL_COOKS_SUCCESS,
    payload: data,
  };
};
const fetchAllCooksFailure = (error) => {
  return {
    type: FETCH_ALL_COOKS_FAILURE,
    payload: error,
  };
};

const flagCookRequest = () => {
  return {
    type: FLAG_COOK_REQUEST,
  };
};
const flagCookSuccess = (data) => {
  return {
    type: FLAG_COOK_SUCCESS,
    payload: data,
  };
};
const flagCookFailure = (error) => {
  return {
    type: FLAG_COOK_FAILURE,
    payload: error,
  };
};

const setAllCooks = (updatedCooksArray) => {
  return { type: SET_ALL_COOKS, payload: updatedCooksArray };
};

const saveHomePageCurrentFilters = (currentFilters) => {
  return { type: SAVE_HOME_PAGE_CURRENT_FILTERS, payload: currentFilters };
};

const saveAssignedCooksCurrentFilters = (currentFilters) => {
  return { type: SAVE_ASSIGNED_COOKS_CURRENT_FILTERS, payload: currentFilters };
};

// set All cooks after flagging a cook + make req to the server to save it
export const setCooksAfterFlag = (
  cook_id,
  updatedCooksArray,
  currentCook,
  flag_reason
) => {
  return (dispatch) => {
    dispatch(setAllCooks(updatedCooksArray));
    dispatch(flagCookRequest());
    axios({
      method: "PATCH",
      url: `${BASE_URL}/admin/cook/${cook_id}/flag`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
      data: {
        type: currentCook[0].flagged === 1 ? "flag" : "unflag",
        ...(currentCook[0].flagged === 1 && {
          flag_reason: flag_reason,
        }),
      },
    })
      .then((res) => {
        dispatch(flagCookSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.response.data.message;
        dispatch(flagCookFailure(errorMsg));
      });
  };
};

// Send fetch all cooks request
export const fetchAllCooks = (
  activePage,
  {
    profileStatusFilter = null,
    opsStatsFilter = null,
    assignedUserFilter = null,
    onboarding_poc = null,
    holidayFilter = null,
    searchTerm = null,
    currentCityFilter = null,
    departmentFilter = null,
    secDepFilter = null,

    homeTownCityFilter = null,
    postFilter = null,
    levelFilter = null,
    typeFilter = null,
    flagged = null,
    profile_percentage = "",
    data_source = "",
    logged_inFilter = null,
    walletAmount = null,
    area = null,
    sort_key = null,
    sort_dir = null,
    bookings_completed = null,
    enriched = null,
    can_accept_bookings = null,
    cook_ids = null,
    cuisines = "",
    logged_in = "",
    serviceTypeFilter = null,
    isPro = "",
    region = "",
    expo_push_token = "",
    post = "",
    contractSigned = null,
    physicalMeeting = null,
    trialDishes = null,
    transport = null,
  },
  page_source
) => {
  let url = `${BASE_URL}/admin/cook/cooks?_limit=50&_page=${activePage}&sort_dir=desc`;

  if (profileStatusFilter !== null) url += `&profile=${profileStatusFilter}`;
  if (opsStatsFilter !== null) url += `&ops_status=${opsStatsFilter}`;
  if (assignedUserFilter !== null)
    url += `&assigned_to=${assignedUserFilter.value}`;
  if (onboarding_poc !== null) url += `&onboarding_poc=${onboarding_poc.value}`;
  if (serviceTypeFilter)
    url += `&can_accept_service_types=${serviceTypeFilter.value}`;
  if (isPro) url += `&pro_partner=${isPro.value}`;
  if (holidayFilter !== null) url += `&leaves_per_month=${holidayFilter.value}`;
  if (searchTerm !== null) url += `&query=${searchTerm}`;
  if (currentCityFilter !== null)
    url += `&current_city=${currentCityFilter.value}`;
  if (departmentFilter !== null) url += `&department=${departmentFilter.value}`;
  if (secDepFilter !== null) url += `&secondary_department=${secDepFilter}`;
  if (homeTownCityFilter !== null)
    url += `&native_city=${homeTownCityFilter.value}`;
  if (postFilter !== null) url += `&post=${postFilter}`;
  if (levelFilter !== null) url += `&level=${levelFilter}`;
  if (typeFilter !== null) url += `&type=${typeFilter}`;
  if (flagged !== null) url += `&flagged=${flagged}`;
  if (profile_percentage !== "")
    url += `&profile_percentage=${profile_percentage.value}`;
  if (data_source !== "") url += `&data_source=${data_source.value}`;
  if (logged_inFilter !== null) url += `&logged_in=${logged_inFilter}`;
  if (walletAmount !== null) url += `&wallet_amount=${walletAmount.value}`;
  if (contractSigned !== null)
    url += `&contract_signed=${contractSigned.value}`;
  if (physicalMeeting) url += `&physical_meeting=${physicalMeeting.value}`;
  if (trialDishes) url += `&trial_dishes=${trialDishes.value}`;
  if (transport) url += `&transport=${transport.value}`;
  if (sort_key !== null) url += `&sort_key=${sort_key}`;
  if (sort_dir !== null) url += `&sort_dir=${sort_dir}`;

  if (area) url += `&area=${area.value}`;
  if (bookings_completed !== null)
    url += `&bookings_completed=${bookings_completed}`;
  if (enriched !== null) url += `&enriched=${enriched}`;
  if (can_accept_bookings !== null)
    url += `&can_accept_bookings=${can_accept_bookings}`;
  if (cook_ids) url += `&cook_ids=${cook_ids}`;
  if (cuisines) url += `&cuisine_id=${cuisines}`;
  if (logged_in) url += `&logged_in=${logged_in.value}`;
  if (region) url += `&region=${region.value}`;
  if (expo_push_token) url += `&expo_push_token=${expo_push_token.value}`;
  if (post) url += `&post=${post}`;
  return (dispatch) => {
    if (page_source === "home_page") {
      dispatch(
        saveHomePageCurrentFilters({
          profileStatusFilter,
          opsStatsFilter,
          assignedUserFilter,
          onboarding_poc,
          holidayFilter,
          searchTerm,
          currentCityFilter,
          serviceTypeFilter,
          isPro,
          departmentFilter,
          secDepFilter,
          homeTownCityFilter,
          postFilter,
          levelFilter,
          typeFilter,
          flagged,
          profile_percentage,
          data_source,
          logged_inFilter,
          walletAmount,
          area,
          bookings_completed,
          enriched,
          can_accept_bookings,
          cuisines,
          logged_in,
          region,
          expo_push_token,
          post,
          contractSigned,
          trialDishes,
          physicalMeeting,
          transport,
        })
      );
    } else if (page_source === "assigned_cooks") {
      dispatch(
        saveAssignedCooksCurrentFilters({
          profileStatusFilter,
          opsStatsFilter,
          assignedUserFilter,
          onboarding_poc,
          holidayFilter,
          serviceTypeFilter,
          isPro,
          searchTerm,
          currentCityFilter,
          departmentFilter,
          secDepFilter,
          homeTownCityFilter,
          postFilter,
          levelFilter,
          typeFilter,
          flagged,
          profile_percentage,
          data_source,
          logged_inFilter,
          walletAmount,
          area,
          bookings_completed,
          enriched,
          can_accept_bookings,
          cuisines,
          logged_in,
          region,
          expo_push_token,
          post,
          contractSigned,
          trialDishes,
          physicalMeeting,
          transport,
        })
      );
    }
    dispatch(fetchAllCooksRequest());
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        const data = res.data;
        dispatch(fetchAllCooksSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchAllCooksFailure(errorMsg));
        if(err.response?.status !== 401) {
          alert(errorMsg);
        }
        console.log(errorMsg, "error ka msg");
      });
  };
};
